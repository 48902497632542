import React from "react";
import { Grid } from "@mui/material";
import { AnalyzedProj } from "../api/harvest-repo-api-types";
import { MetricsList, MetricsItem, MetricsTitle } from "./MetricsList";

const extractJavaMetrics = (analyzedProj: AnalyzedProj) => {
  return (
    <>
      <MetricsItem
        name="Number of classes"
        value={analyzedProj.java_proj.data.num_classes}
      />
      <MetricsItem
        name="Number of successfully parsed files"
        value={
          analyzedProj.java_proj.data.java_parse_confidence.successful_files
        }
      />
      <MetricsItem
        name="Number of failed parsed files"
        value={analyzedProj.java_proj.data.java_parse_confidence.failed_files}
      />
    </>
  );
};

const extractUmlMetrics = (analyzedProj: AnalyzedProj) => {
  return (
    <>
      <MetricsItem
        name="Number of diagrams"
        value={analyzedProj.uml_proj.data.num_diagrams}
      />
      <MetricsItem
        name="Number of classes"
        value={analyzedProj.uml_proj.data.num_classes}
      />
      <MetricsItem
        name="Number of successfully parsed lines"
        value={
          analyzedProj.uml_proj.data.uml_parse_confidence.num_successful_lines
        }
      />
      <MetricsItem
        name="Number of failed parsed lines"
        value={analyzedProj.uml_proj.data.uml_parse_confidence.num_failed_lines}
      />
    </>
  );
};

interface Props {
  analyzedProj: AnalyzedProj;
}
export const MetricsViewer = ({ analyzedProj }: Props) => {
  const javaMetrics = analyzedProj ? extractJavaMetrics(analyzedProj) : [];
  const umlMetrics = analyzedProj ? extractUmlMetrics(analyzedProj) : [];

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={6}>
        <MetricsList>
          <MetricsTitle title="Java Project Metrics" />
          {javaMetrics}
        </MetricsList>
      </Grid>
      <Grid item xs={6}>
        <MetricsList>
          <MetricsTitle title="UML Diagram Metrics" />
          {umlMetrics}
        </MetricsList>
      </Grid>
    </Grid>
  );
};
