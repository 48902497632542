import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const stringToDropdownItem = (item: string) => {
  return (
    <Typography variant="body2" align="left">
      {item}
    </Typography>
  );
};

type MetricsDropdownListProps = {
  title: string;
  items: JSX.Element[];
};
export const MetricsItemDropdownList = ({
  title,
  items,
}: MetricsDropdownListProps) => {
  return (
    <Accordion elevation={0} disableGutters={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" align="left">
          {title} ({items.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{items}</AccordionDetails>
    </Accordion>
  );
};
