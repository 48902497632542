import React from "react";
import "./StickyButton.css";

interface StickyButtonProps {
  showButton: boolean;
  buttonContent: React.ReactNode | string;
  onClick: () => void;
}

const StickyButton: React.FC<StickyButtonProps> = ({
  showButton,
  buttonContent,
  onClick,
}) => {
  return (
    <>
      {showButton && (
        <button className={`sticky-button`} onClick={onClick}>
          {buttonContent}
        </button>
      )}
    </>
  );
};

export default StickyButton;
