import React from "react";
import { RepoInfo } from "../api/harvest-repo-api-types";
import {
  MetricsItemLink,
  MetricsList,
  MetricsItem,
  MetricsTitle,
} from "./MetricsList";

const extractRepoInfo = (repoInfo: RepoInfo) => {
  return (
    <>
      <MetricsItemLink name="Link" url={`http://github.com/${repoInfo.name}`} />
      <MetricsItem name="Forks" value={repoInfo.info.forks} />
      <MetricsItem name="Stars" value={repoInfo.info.stars} />
      <MetricsItem name="Watchers" value={repoInfo.info.watchers} />
      <MetricsItem
        name="Number of Releases"
        value={repoInfo.info.releases_info.length}
      />
    </>
  );
};

interface Props {
  repoInfo: RepoInfo;
}
export const RepoInfoViewer = ({ repoInfo }: Props) => {
  const repoMetrics = extractRepoInfo(repoInfo);

  return (
    <MetricsList>
      <MetricsTitle title="Repo Info" />
      {repoMetrics}
    </MetricsList>
  );
};
