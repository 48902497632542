import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  showUnconnected: boolean;
  onChangeShowUnconnected: (showUnconnected: boolean) => void;
  showInterfaces: boolean;
  onChangeShowInterfaces: (showInterfaces: boolean) => void;
  showEnums: boolean;
  onChangeShowEnums: (showEnums: boolean) => void;
}
export const GraphVizSelectors = ({
  showUnconnected,
  onChangeShowUnconnected,
  showInterfaces,
  onChangeShowInterfaces,
  showEnums,
  onChangeShowEnums,
}: Props) => {
  return (
    <Grid container spacing={2} direction="column" flexDirection="column">
      <Grid item>
        <Paper elevation={8}>
          <Box p={1.5}>
            <Typography variant="h6" align="left">
              Selectors
            </Typography>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUnconnected}
                    onChange={(e) => onChangeShowUnconnected(e.target.checked)}
                  />
                }
                label="Unconnected"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showInterfaces}
                    onChange={(e) => onChangeShowInterfaces(e.target.checked)}
                  />
                }
                label="Interfaces"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showEnums}
                    onChange={(e) => onChangeShowEnums(e.target.checked)}
                  />
                }
                label="Enums"
              />
            </FormGroup>
          </Box>
        </Paper>
      </Grid>
      <Grid item />
    </Grid>
  );
};
