import {
  JavaPack,
  JavaProj,
  JavaRef,
  isJavaRef,
} from "../api/harvest-repo-api-types";
import { Node } from "../components/CirclePack";

export interface JavaNode extends Node {
  data: JavaRef | JavaPack | "root";
}

export const javaProj2Node = (javaProj: JavaProj): JavaNode => {
  const javaRef2Node = (javaRef: JavaRef): JavaNode => {
    return {
      name: javaRef.data.name,
      value: javaRef.data.metrics.num_methods,
      data: javaRef,
      children: [],
    };
  };

  const javaPack2Node =
    (parentName: string) =>
    (javaPack: JavaPack): JavaNode => {
      const name =
        parentName !== ""
          ? parentName + "." + javaPack.data.name
          : javaPack.data.name;

      return {
        name: name,
        value: 1,
        data: javaPack,
        children: javaPack.children.map((child) => {
          if (isJavaRef(child)) {
            return javaRef2Node(child);
          } else {
            return javaPack2Node(name)(child);
          }
        }),
      };
    };

  return {
    name: "root",
    data: "root",
    children: javaProj.children.map(javaPack2Node("")),
  };
};
