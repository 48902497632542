import React, { useEffect } from "react";
import { runForceGraph, GraphVizEdge, GraphVizNode } from "./force-graph";

interface Props<N, E> {
  nodesData: GraphVizNode<N>[];
  linksData: GraphVizEdge<E>[];
  width: number;
  height: number;
  onMouseOver?: (node: GraphVizNode<N>) => void;
  onMouseOut?: (node: GraphVizNode<N>) => void;
  onSelected?: (connected: GraphVizNode<N>[], edges: GraphVizEdge<E>[]) => void;
}

export function ForceGraph<N, E>({
  nodesData,
  linksData,
  width,
  height,
  onMouseOver,
  onMouseOut,
  onSelected,
}: Props<N, E>) {
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = runForceGraph(
        containerRef.current,
        nodesData,
        linksData,
        onMouseOver,
        onMouseOut,
        onSelected
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [nodesData, linksData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={containerRef}
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  );
}
