import Prism from "prismjs";
import React, { useEffect } from "react";

export type Language = "plant-uml";

interface Props {
  language: Language;
  code: string;
}
export const CodeBlock = ({ language, code }: Props) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  //const highlightedCode = Prism.highlight(
  //  code,
  //  Prism.languages[language],
  //  language
  //);

  return (
    <>
      <pre className={`language-${language}`}>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </>
  );
};
