import React from "react";
import { Modal, Box } from "@mui/material";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";

import "./SuggestionModal.css";

export interface Suggestion {
  id: number;
  title: string;
  subTitle: string;
  matches: {
    id: number;
    title: string;
    subTitle: string;
  }[];
}

const SuggestionSubItems = ({ suggestion }: { suggestion: Suggestion }) => {
  return (
    <ul className="suggestion-subitem-list">
      {suggestion.matches.map((match) => (
        <li key={match.id} className="suggestion-list-subitem">
          <div className="suggestion-subitem-content">
            <div className="suggestion-subitem-heading">{match.title}</div>
            <div className="suggestion-subitem-subheading">
              {match.subTitle}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

interface SuggestionProps {
  suggestion: Suggestion;
  onSuggestionAccepted: (suggestion: Suggestion) => void;
}
const SuggestionItem = ({
  suggestion,
  onSuggestionAccepted,
}: SuggestionProps) => {
  return (
    <li key={suggestion.id} className="suggestion-list-item">
      <div className="suggestion-item-content">
        <div className="suggestion-item-heading">{suggestion.title}</div>
        <div className="suggestion-item-subheading">{suggestion.subTitle}</div>
        <SuggestionSubItems suggestion={suggestion} />
      </div>
      <FaCheck
        onClick={() => onSuggestionAccepted(suggestion)}
        className="suggestion-accept-button"
      />
    </li>
  );
};

interface SuggestionModalProps {
  open: boolean;
  onClose: () => void;
  suggestions: Suggestion[];
  onSuggestionAccepted: (suggestion: Suggestion) => void;
}
export const SuggestionModal = ({
  open,
  onClose,
  suggestions,
  onSuggestionAccepted,
}: SuggestionModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box style={{ whiteSpace: "pre-line" }} className="suggestion-text-modal">
        <h1>Author Suggestions</h1>
        <div className="suggestion-item-list">
          <ul>
            {suggestions.map((suggestion) => (
              <SuggestionItem
                key={suggestion.id}
                suggestion={suggestion}
                onSuggestionAccepted={onSuggestionAccepted}
              />
            ))}
          </ul>
        </div>
      </Box>
    </Modal>
  );
};
