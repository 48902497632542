import { ListItem, Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
}
export const MetricsTitle = ({ title }: Props) => {
  return (
    <ListItem key={title}>
      <Typography variant="h6" align="left">
        {title}
      </Typography>
    </ListItem>
  );
};
