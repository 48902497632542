import ReactEcharts from "echarts-for-react";
import { EChartsOption } from "echarts-for-react";
import {
  CoverageMap,
  FileHistory,
  FileSnapshot,
} from "../api/harvest-repo-api-types";
interface LineSegment {
  startIndex: number;
  endIndex: number;
  category: string;
  color: string;
  currSnapshot: FileSnapshot;
  prevSnapshot: FileSnapshot;
}

interface LineSegmentDataSource {
  commitIndex: number;
  category: string;
  color: string;
  snapshot: FileSnapshot;
}

interface LineSegmentData {
  dimensions: {
    name: string;
  }[];
  source: LineSegmentDataSource[];
}

const tooltipSection = (
  title: string,
  values: [string, string | number][]
): string => {
  const htmlSpace = "&nbsp;";
  const maxKeyLength = values.reduce(
    (acc, [key]) => Math.max(acc, key.length),
    0
  );

  const content = values
    .map(([key, value]) => {
      const numSpaces = maxKeyLength - key.length;
      return `<p align=left style="margin:0"><b>${key}</b>:${htmlSpace.repeat(
        numSpaces
      )} ${value}</p>`;
    })
    .join("");

  const titleText = `<h4 align=center>${title}</h4>`;

  return titleText + content;
};

const getFileParts = (file: string) => {
  const [fileName, ...pathReversed] = file.split("/").reverse();
  return { filePath: pathReversed.reverse().join("/"), fileName: fileName };
};

interface TooltipFormatterParams {
  value: LineSegmentDataSource;
}

interface ItemStyleParams {
  data: LineSegmentDataSource;
}

const coveredColor = (covered: boolean) => (covered ? "#0a660a" : "#f54545");

interface Props {
  coverageMap: CoverageMap;
}
export const FileHistoryViewer = ({ coverageMap }: Props) => {
  const commitsIndexMap = new Map<string, number>(
    coverageMap.commits.map((commit, index) => [commit, index])
  );

  const buildLineSegments =
    (category: string) =>
    (fileHistory: FileHistory): LineSegment[] => {
      return fileHistory.commits.reduce((acc, snapshot, i, arr) => {
        if (i === 0 || snapshot === null) {
          return acc;
        }

        const prevSnapshot = arr[i - 1];
        if (prevSnapshot === null) {
          return acc;
        }

        acc.push({
          startIndex: i - 1,
          endIndex: i,
          category: category,
          color: coveredColor(prevSnapshot.covered),
          currSnapshot: snapshot,
          prevSnapshot: prevSnapshot,
        });

        return acc;
      }, [] as LineSegment[]);
    };

  const lineSegment2DataSource = (
    lineSegment: LineSegment
  ): LineSegmentData => {
    return {
      dimensions: [
        { name: "commitIndex" },
        { name: "category" },
        { name: "color" },
        { name: "snapshot" },
      ],
      source: [
        {
          commitIndex: lineSegment.startIndex,
          category: lineSegment.category,
          color: lineSegment.color,
          snapshot: lineSegment.prevSnapshot,
        },
        {
          commitIndex: lineSegment.endIndex,
          category: lineSegment.category,
          color: lineSegment.color,
          snapshot: lineSegment.currSnapshot,
        },
      ],
    };
  };

  const getFileName = (fileHistory: FileHistory) => {
    return fileHistory.commits.reduce(
      (acc, curr) => (acc === "" && curr !== null ? curr.file_name : acc),
      ""
    );
  };

  const javaFileName = getFileName(coverageMap.java_file);
  const javaFileSegments = buildLineSegments(`0: ${javaFileName}`)(
    coverageMap.java_file
  );
  const diagramSegments = coverageMap.diagrams
    .sort((d0, d1) => {
      const d0Name = getFileName(d0);
      const d1Name = getFileName(d1);
      return d1Name.localeCompare(d0Name);
    })
    .flatMap((diagramHistory, i) =>
      buildLineSegments(`${i}:${getFileName(diagramHistory)}`)(diagramHistory)
    );

  const lineSegments = [...diagramSegments, ...javaFileSegments];
  const lineSegmentDataSource = lineSegments.map(lineSegment2DataSource);

  const option: EChartsOption = {
    dataset: lineSegmentDataSource,
    grid: {
      containLabel: true,
    },
    tooltip: {
      textStyle: {
        fontFamily: "monospace",
      },

      formatter: (params: TooltipFormatterParams) => {
        const { snapshot, category } = params.value;

        const fileInfoSection = tooltipSection("File Information", [
          ["Current File Name", snapshot.file_name],
          ["Original File Name", category.split(":")[1]],
          ["Change Type", snapshot.change_type],
        ]);

        return (
          fileInfoSection +
          tooltipSection("Commit Information", [
            ["Commit Hash", snapshot.commit.commit_hash],
            ["Commit Message", snapshot.commit.message],
            ["Commit Author", snapshot.commit.author_name],
            ["Commit Author Email", snapshot.commit.author_email],
            ["Commit Date", snapshot.commit.authored_date],
            ["Committer Name", snapshot.commit.committer_name],
            ["Committer Email", snapshot.commit.committer_email],
            ["Committer Date", snapshot.commit.committed_date],
          ])
        );
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        xAxisIndex: [0],
      },
      {
        type: "slider",
        show: true,
        yAxisIndex: [0],
      },
    ],
    xAxis: {
      type: "value",
      name: "commitIndex",
      min: 0,
      max: commitsIndexMap.size,
    },
    yAxis: {
      type: "category",
      name: "category",
      axisLabel: {
        interval: 0,
        fontSize: 15,
        formatter: (value: string, index: number) => {
          const [, file] = value.split(":").map((s) => s.trim());
          const { filePath, fileName } = getFileParts(file);
          const fullName = `${filePath}/${fileName}`;

          const maxLineLength = 50;

          // include version, full path, and file name
          if (fullName.length <= maxLineLength) {
            return fullName;
          }
          // include version, partial path, and file name
          else if (`.../${fileName}}`.length <= maxLineLength) {
            const numFilePathChars = Math.max(
              0,
              maxLineLength - `.../${fileName}`.length
            );

            return `${filePath.slice(0, numFilePathChars)}.../${fileName}`;
          }

          return fileName;
        },
      },
    },
    series: lineSegmentDataSource.map((dataset, index) => {
      return {
        type: "line",
        datasetIndex: index,
        encode: {
          x: "commitIndex",
          y: "category",
        },
        symbolSize: 7.5,
        symbol: (params: LineSegmentDataSource) => {
          return params.snapshot.change_type === "added"
            ? "emptySquare"
            : params.snapshot.change_type === "deleted"
            ? "emptyDiamond"
            : params.snapshot.change_type === "modified"
            ? "emptyCircle"
            : params.snapshot.change_type === "renamed"
            ? "emptyTriangle"
            : params.snapshot.change_type === "no_change"
            ? "none"
            : "circle";
        },
        itemStyle: {
          color: (params: ItemStyleParams) =>
            coveredColor(params.data.snapshot.covered),
        },
        lineStyle: {
          width: 2.5,
          color: dataset.source[0].color,
        },
      };
    }),
  };

  return <ReactEcharts option={option} notMerge={true} />;
};
