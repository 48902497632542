import * as React from "react";
import Slider from "@mui/material/Slider";
import { ReleaseInfo } from "../api/harvest-repo-api-types";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft";
import "./ReleaseSelector.css";

interface Props {
  releases: ReleaseInfo[];
  currentReleaseIndex: number;
  onReleaseChange: (release: string) => void;
}
export default function ReleaseSelector({
  releases,
  currentReleaseIndex,
  onReleaseChange,
}: Props) {
  const [searchTerm, setSearchTerm] = useState("");
  const labelText = (value: number) => {
    return `${releases[value].tag_name}`;
  };

  const handleSearch = () => {
    onReleaseChange(searchTerm);
  };

  const handleLeft = () => {
    if (currentReleaseIndex > 0) {
      onReleaseChange(releases[currentReleaseIndex - 1].tag_name);
    }
  };

  const handleRight = () => {
    if (currentReleaseIndex < releases.length - 1) {
      onReleaseChange(releases[currentReleaseIndex + 1].tag_name);
    }
  };

  return (
    <Paper elevation={4}>
      <Box p={2} m={2}>
        <Grid container justifyContent="flex-start" spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              Release Selector
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <div className="button-arrow-container">
              <button className="button-arrow" onClick={handleLeft}>
                <FaAngleLeft />
              </button>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className="button-arrow-container">
              <button className="button-arrow" onClick={handleRight}>
                <FaAngleRight />
              </button>
            </div>
          </Grid>
          <Grid item xs={10}>
            <Slider
              key={releases[0].html_url}
              aria-label="Small steps"
              value={currentReleaseIndex}
              getAriaValueText={labelText}
              valueLabelFormat={labelText}
              onChangeCommitted={(e, value) => {
                onReleaseChange(releases[value as number].tag_name);
              }}
              marks
              min={0}
              max={releases.length - 1}
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="autocomplete-bar"
              options={releases.map((r) => r.tag_name)}
              onInputChange={(e, value) =>
                value ? setSearchTerm(value) : setSearchTerm("")
              }
              renderInput={(params) => <TextField {...params} label="commit" />}
            />
            <Button
              id="search-button"
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={handleSearch}
            >
              Select
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
