import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Aggregation, aggregations } from "../utils/metrics-calculator";

import "./AggregationTypeSelector.css";

type Props = {
  onChange: (aggregationType: Aggregation) => void;
};
export const AggregationTypeSelector = ({ onChange }: Props) => {
  return (
    <FormControl id="aggregation-form">
      <FormLabel>Metrics Aggregation Type</FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        onChange={(e) => onChange(e.target.value as Aggregation)}
        defaultValue={aggregations[0]}
      >
        {aggregations.map((a) => (
          <FormControlLabel key={a} value={a} control={<Radio />} label={a} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
