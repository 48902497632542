import {
  EdgeMetric,
  RefEdge,
  JavaPack,
  JavaRef,
} from "../api/harvest-repo-api-types";
import { getRefs } from "./java-proj-functions";

export const aggregations = ["avg", "max", "min"] as const;
export type Aggregation = (typeof aggregations)[number];

type AggregationFunction = (values: number[]) => number;

const sum = (values: number[]): number => {
  return values.reduce((acc, value) => acc + value, 0);
};

const avg = (values: number[]): number => {
  return values.length > 0 ? sum(values) / values.length : 0;
};

const max = (values: number[]): number => {
  return Math.max(...values);
};

const min = (values: number[]): number => {
  return Math.min(...values);
};

const selectMetric =
  (metricType: EdgeMetric) =>
  (edge: RefEdge): number => {
    switch (metricType) {
      case "Coverage":
        return 1.0;

      case "Detailedness: Attributes Coverage":
        return edge.data.detailedness.attributes_coverage;

      case "Detailedness: Method Coverage":
        return edge.data.detailedness.methods_coverage;
    }
  };

const selectAggregation = (
  aggregationType: Aggregation
): AggregationFunction => {
  switch (aggregationType) {
    case "avg":
      return avg;

    case "max":
      return max;

    case "min":
      return min;
  }
};

export const calculateAggregatedMetric = (
  node: JavaPack | JavaRef,
  metricType: EdgeMetric,
  aggregationType: Aggregation,
  edgeMap: Map<number, RefEdge[]>
) => {
  const metrics = getRefs(node)
    .map((c) => edgeMap.get(c.id))
    .map((e) => (e ? calculateEdgeMetrics(metricType)(aggregationType)(e) : 0));

  return selectAggregation(aggregationType)(metrics);
};

export const getLeafRefs = (node: JavaPack | JavaRef) => {
  if (node.type !== "JavaPackNode") {
    return [];
  }

  return getRefs(node);
};

export const getRefNames = (node: JavaPack | JavaRef) => {
  getLeafRefs(node)
    .map((c) => c.data.name)
    .sort();
};

export const calculateEdgeMetrics =
  (metricType: EdgeMetric) =>
  (aggregationType: Aggregation) =>
  (edges: RefEdge[]): number => {
    const metrics = edges.map(selectMetric(metricType));

    return selectAggregation(aggregationType)(metrics);
  };
