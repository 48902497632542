import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { edgeMetrics, EdgeMetric } from "../api/harvest-repo-api-types";

import "./MetricTypeSelector.css";

type Props = {
  edgeMetric: EdgeMetric;
  onChange: (edgeMetric: EdgeMetric) => void;
};
export const MetricTypeSelector = ({ edgeMetric, onChange }: Props) => {
  return (
    <FormControl id="metric-selector">
      <FormLabel>Metrics Selection</FormLabel>
      <RadioGroup
        onChange={(e) => onChange(e.target.value as EdgeMetric)}
        value={edgeMetric}
      >
        {edgeMetrics.map((a) => (
          <FormControlLabel key={a} value={a} control={<Radio />} label={a} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
