import React from "react";
import { Modal, Box } from "@mui/material";

import "./ProgressModal.css";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  progress: string;
}
export const ProgressModal = ({ open, onClose, title, progress }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box style={{ whiteSpace: "pre-line" }} className="suggestion-text-modal">
        <h1>{title}</h1>
        <p>{progress}</p>
      </Box>
    </Modal>
  );
};
