import React from "react";
import { ListItem, ListItemText } from "@mui/material";

interface Props {
  name: string;
  url: string;
}
export const MetricsItemLink = ({ name, url }: Props) => {
  return (
    <ListItem key={name} component="a" href={url}>
      <ListItemText primary={url} primaryTypographyProps={{ noWrap: true }} />
    </ListItem>
  );
};
