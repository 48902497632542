import { Modal, Box, Link, Typography } from "@mui/material";
import { CodeBlock, Language } from "../../utils/CodeBlock";
import React, { useState } from "react";
import "./MetricsItemCodeBlock.css";

interface Props {
  name: string;
  code: string;
  // add languages from https://prismjs.com/as needed
  language: Language;
}
export const MetricsItemCodeBlock = ({ name, code, language }: Props) => {
  const [openDiagramModal, setOpenDiagramModal] = useState(false);

  const handleClick = () => {
    setOpenDiagramModal(true);
  };

  const link = (
    <Typography align="left" variant="body2">
      <Link onClick={handleClick}>{name.split("/").at(-1)}</Link>
    </Typography>
  );

  return (
    <>
      <Modal open={openDiagramModal} onClose={() => setOpenDiagramModal(false)}>
        <Box style={{ whiteSpace: "pre-line" }} className="text-modal">
          <p>
            <b>File: </b> {name} <br />
          </p>
          <CodeBlock language={language} code={code} />
        </Box>
      </Modal>
      {link}
    </>
  );
};
