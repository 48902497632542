import { Paper, Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import {
  CoverageHistory,
  JavaProjHistory,
} from "../api/harvest-repo-api-types";
import { LineChart } from "./LineChart";

interface Props {
  coverageHistory: CoverageHistory;
  javaProjHistory: JavaProjHistory;
  onSelectCommit: (commit: string) => void;
}
const HistoryVisualizerCard = ({
  coverageHistory,
  javaProjHistory,
  onSelectCommit,
}: Props) => {
  return (
    <Paper elevation={4}>
      <Box p={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Project History</Typography>
          </Grid>
          <Grid item xs={12}>
            <LineChart
              coverageHistory={coverageHistory}
              javaProjHistory={javaProjHistory}
              onSelectCommit={onSelectCommit}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default memo(HistoryVisualizerCard, (prevProps, nextProps) => {
  return (
    prevProps.coverageHistory === nextProps.coverageHistory &&
    prevProps.javaProjHistory === nextProps.javaProjHistory
  );
});
