import axios from "axios";

import {
  Author,
  cleanedAuthorsAddResponse,
  getCleanedAuthorsSchema,
  getRawAuthorsSchema,
} from "./author-cleaner-api-types";

const authorCleanerApiUrl = `${process.env.REACT_APP_HARVEST_FLASK_API_URL}/authors`;

export const AuthorCleanerApi = {
  getAuthorsRaw: async (repoName: string) => {
    const response = await axios.get(`${authorCleanerApiUrl}/raw`, {
      params: { repo_name: repoName },
    });

    return getRawAuthorsSchema.parse(response.data).authors;
  },

  getAuthorsMap: async (repoName: string) => {
    const authorsRaw = await AuthorCleanerApi.getAuthorsRaw(repoName);

    return new Map<number, Author>(
      authorsRaw.map((author) => [author.id, author])
    );
  },

  getAuthorsCleaned: async (repoName: string) => {
    const response = await axios.get(`${authorCleanerApiUrl}/cleaned`, {
      params: { repo_name: repoName },
    });

    return getCleanedAuthorsSchema.parse(response.data).authors;
  },

  addCleanAuthor: async (repoName: string, author: Author) => {
    const response = await axios.post(`${authorCleanerApiUrl}/cleaned`, {
      params: {
        repo_name: repoName,
        author_name: author.name,
        author_email: author.email,
      },
    });

    return cleanedAuthorsAddResponse.parse(response.data).author_id;
  },

  addAuthorAlias: async (cleanAuthorId: number, aliasAuthorId: number) => {
    await axios.post(`${authorCleanerApiUrl}/cleaned/${cleanAuthorId}/alias`, {
      params: { author_raw_id: aliasAuthorId },
    });
  },

  updateAuthorAlias: async (
    cleanAuthorId: number,
    authorName: string,
    authorEmail: string
  ) => {
    await axios.put(`${authorCleanerApiUrl}/cleaned/${cleanAuthorId}`, {
      params: { author_name: authorName, author_email: authorEmail },
    });
  },

  mergeAuthors: async (repoName: string, authors: Author[]) => {
    const mainAuthor = authors[0];

    const authorId = await AuthorCleanerApi.addCleanAuthor(
      repoName,
      mainAuthor
    );

    authors.forEach(async (author) => {
      await AuthorCleanerApi.addAuthorAlias(authorId, author.id);
    });
  },

  removeAlias: async (cleanAuthorId: number, aliasAuthorId: number) => {
    await axios.delete(
      `${authorCleanerApiUrl}/cleaned/${cleanAuthorId}/alias`,
      {
        params: { author_raw_id: aliasAuthorId },
      }
    );
  },
};
