import React from "react";
import "./ErrorModal.css";

type Props = {
  errorMessage: string;
  onClose: () => void;
};
const ErrorModal = ({ errorMessage, onClose }: Props) => {
  if (errorMessage === "") return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal">
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Error</h2>
        <p>{errorMessage}</p>
      </div>
    </>
  );
};

export default ErrorModal;
