import { z } from "zod";

export const getRepoListSchema = z.array(z.string());
export type RepoList = z.infer<typeof getRepoListSchema>;

export type CommitView = "releases" | "commits";

const releaseInfoSchema = z.object({
  created_date: z.string(),
  html_url: z.string(),
  tag_name: z.string(),
});
export type ReleaseInfo = z.infer<typeof releaseInfoSchema>;

export const getRepoInfoSchema = z.object({
  info: z.object({
    default_branch: z.string(),
    forks: z.number(),
    releases_info: z.array(releaseInfoSchema),
    stars: z.number(),
    watchers: z.number(),
  }),
  name: z.string(),
});
export type RepoInfo = z.infer<typeof getRepoInfoSchema>;

////// analyzed project

const umlProjMetricsSchema = z.object({
  uml_parse_confidence: z.object({
    num_successful_lines: z.number(),
    num_failed_lines: z.number(),
  }),
  num_diagrams: z.number(),
  num_classes: z.number(),
  num_interfaces: z.number(),
});

const javaProjMetricsSchema = z.object({
  java_parse_confidence: z.object({
    successful_files: z.number(),
    failed_files: z.number(),
  }),
  num_classes: z.number(),
  num_interfaces: z.number(),
});

const umlClassSchema = z.object({
  id: z.number(),
  data: z.object({
    name: z.string(),
    metrics: z.object({ num_methods: z.number(), num_attributes: z.number() }),
  }),
  type: z.literal("UmlClassNode"),
});

const umlInterfaceSchema = z.object({
  id: z.number(),
  data: z.object({
    name: z.string(),
    metrics: z.object({ num_methods: z.number(), num_attributes: z.number() }),
  }),
  type: z.literal("UmlInterfaceNode"),
});

const umlRefSchema = umlClassSchema.or(umlInterfaceSchema);
export type UmlRef = z.infer<typeof umlRefSchema>;

const umlFileSchema = z.object({
  id: z.number(),
  path: z.string(),
  raw_content: z.string(),
  refs: umlRefSchema.array(),
});
export type UmlFile = z.infer<typeof umlFileSchema>;
export function isUmlFile(refOrFile: JavaRef | UmlFile): refOrFile is UmlFile {
  return (refOrFile as UmlFile).path !== undefined;
}

const umlProjSchema = z.object({
  id: z.number(),
  children: umlRefSchema.array(),
  diagrams: umlFileSchema.array(),
  data: umlProjMetricsSchema,
  type: z.literal("UmlProjNode"),
});
export type UmlProj = z.infer<typeof umlProjSchema>;

const javaClassSchema = z.object({
  id: z.number(),
  data: z.object({
    name: z.string(),
    file: z.string(),
    is_abstract: z.boolean(),
    metrics: z.object({ num_methods: z.number(), num_attributes: z.number() }),
  }),
  type: z.literal("JavaClassNode"),
});
export type JavaClass = z.infer<typeof javaClassSchema>;

const javaInterfaceSchema = z.object({
  id: z.number(),
  data: z.object({
    name: z.string(),
    file: z.string(),
    metrics: z.object({ num_methods: z.number(), num_attributes: z.number() }),
  }),
  type: z.literal("JavaInterfaceNode"),
});
export type JavaInterface = z.infer<typeof javaInterfaceSchema>;

const javaEnumSchema = z.object({
  id: z.number(),
  data: z.object({
    name: z.string(),
    file: z.string(),
    metrics: z.object({ num_methods: z.number(), num_attributes: z.number() }),
  }),
  type: z.literal("JavaEnumNode"),
});
export type JavaEnum = z.infer<typeof javaEnumSchema>;

const javaRefSchema = javaClassSchema
  .or(javaInterfaceSchema)
  .or(javaEnumSchema);
export type JavaRef = z.infer<typeof javaRefSchema>;
export function isJavaRef(
  refOrFile: JavaRef | UmlFile | JavaPack
): refOrFile is JavaRef {
  return (
    (refOrFile as JavaRef).type === "JavaClassNode" ||
    (refOrFile as JavaRef).type === "JavaInterfaceNode" ||
    (refOrFile as JavaRef).type === "JavaEnumNode"
  );
}

interface JavaPackageInterface {
  id: number;
  children: JavaPackChild[];
  data: { name: string; fully_qualified_name: string };
  type: "JavaPackNode";
}
type JavaPackChild = JavaPackageInterface | JavaRef;

const javaPackageSchema: z.ZodSchema<JavaPackageInterface> = z.lazy(() =>
  z.object({
    id: z.number(),
    children: javaPackChild.array(),
    data: z.object({ name: z.string(), fully_qualified_name: z.string() }),
    type: z.literal("JavaPackNode"),
  })
);
const javaPackChild = javaPackageSchema.or(javaRefSchema);
export type JavaPack = z.infer<typeof javaPackageSchema>;

const javaProjSchema = z.object({
  id: z.number(),
  children: javaPackageSchema.array(),
  data: javaProjMetricsSchema,
  type: z.literal("JavaProjNode"),
});
export type JavaProj = z.infer<typeof javaProjSchema>;

export const edgeMetrics = [
  "Coverage",
  "Detailedness: Attributes Coverage",
  "Detailedness: Method Coverage",
] as const;
export type EdgeMetric = (typeof edgeMetrics)[number];
const edgeMetricsSchema = z.object({
  detailedness: z.object({
    attributes_coverage: z.number(),
    methods_coverage: z.number(),
  }),
});
export type EdgeMetrics = z.infer<typeof edgeMetricsSchema>;

const edgeSchema = z.object({
  id: z.number(),
  source: javaRefSchema,
  target: umlRefSchema,
  data: edgeMetricsSchema,
});
export type RefEdge = z.infer<typeof edgeSchema>;

const ref2diagramEdgeSchema = z.object({
  id: z.number(),
  source: javaRefSchema,
  target: umlFileSchema,
});

//const projMetricsSchema = z.object({
//  uml_metrics: umlProjMetricsSchema,
//  java_metrics: javaProjMetricsSchema,
//  coverage: z.number(),
//});

export const analyzedProjSchema = z.object({
  id: z.number(),
  uml_proj: umlProjSchema,
  java_proj: javaProjSchema,
  ref_edges: edgeSchema.array(),
  r2d_edges: ref2diagramEdgeSchema.array(),
  type: z.literal("AnalyzedProjNode"),
});
export type AnalyzedProj = z.infer<typeof analyzedProjSchema>;

export const releaseSchema = z.object({
  info: releaseInfoSchema,
  analyzed_project: analyzedProjSchema,
});
export type Release = z.infer<typeof releaseSchema>;

export const coverageHistorySchema = z.array(
  z.object({
    coverage: z.number(),
    created: z.string(),
    committish: z.string(),
  })
);
export type CoverageHistory = z.infer<typeof coverageHistorySchema>;

export const javaProjHistory = z.array(
  z.object({
    created: z.string(),
    java_proj_data: z.object({
      java_parse_confidence: z.object({
        failed_files: z.number(),
        successful_files: z.number(),
      }),
      num_classes: z.number(),
    }),
    committish: z.string(),
  })
);
export type JavaProjHistory = z.infer<typeof javaProjHistory>;

export const fileNameSchema = z.object({
  name: z.string(),
  count: z.number(),
});
export const fileNamesSchema = z.array(fileNameSchema);
export type FileName = z.infer<typeof fileNameSchema>;

export const commitSchema = z.object({
  author_name: z.string(),
  author_email: z.string(),
  authored_date: z.string(),
  commit_hash: z.string(),
  committer_name: z.string(),
  committer_email: z.string(),
  committed_date: z.string(),
  message: z.string(),
});
export type Commit = z.infer<typeof commitSchema>;

export const fileSnapshotSchema = z.object({
  file_name: z.string(),
  commit: commitSchema,
  covered: z.boolean(),
  change_type: z.enum(["added", "modified", "deleted", "renamed", "no_change"]),
});
export type FileSnapshot = z.infer<typeof fileSnapshotSchema>;

export const fileHistorySchema = z.object({
  commits: fileSnapshotSchema.or(z.null()).array(),
});
export type FileHistory = z.infer<typeof fileHistorySchema>;

export const coverageMapSchema = z.object({
  commits: z.array(z.string()),
  java_file: fileHistorySchema,
  diagrams: fileHistorySchema.array(),
});
export type CoverageMap = z.infer<typeof coverageMapSchema>;
