import { Box, Grid, Paper, Typography } from "@mui/material";
import { CommitView, RepoInfo } from "../api/harvest-repo-api-types";
import { RepoInfoViewer } from "./RepoInfoViewer";
import { RepoSearchBar } from "./RepoSearchBar";

interface Props {
  repoInfo?: RepoInfo;
  onRepoChange: (repoInfo: RepoInfo) => void;
  commitView: CommitView;
  onCommitViewChange: (commitView: CommitView) => void;
}

export const RepoSelectorCard = ({
  repoInfo,
  onRepoChange,
  commitView,
  onCommitViewChange,
}: Props) => {
  return (
    <Paper elevation={4} sx={{ height: "100%" }}>
      <Box p={6} sx={{ height: "100%" }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4">Repo Selection</Typography>
          </Grid>
          <Grid item xs={6}>
            <RepoSearchBar
              onRepoChange={onRepoChange}
              onCommitViewChange={onCommitViewChange}
              commitView={commitView}
            />
          </Grid>
          <Grid item xs={6}>
            {repoInfo && <RepoInfoViewer repoInfo={repoInfo} />}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
