import React, { useState } from "react";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { FaPen } from "@react-icons/all-files/fa/FaPen";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import "./CollapseSelectableList.css";

export interface ListItem {
  id: string;
  heading: string;
  subheading: string;
  subItems: SubItem[];
}
export interface SubItem {
  id: number;
  heading: string;
  subheading: string;
}

const renderSubitems = (
  item: ListItem,
  onSubItemRemoved: (item: ListItem, subItem: SubItem) => void
) => {
  const subItems = item.subItems;
  if (!subItems || subItems.length === 0) {
    return null;
  }

  return (
    <ul className="subitem-list">
      {subItems.map((subItem) => (
        <li key={subItem.id} className="list-item">
          <div className="item-content">
            <div className="item-heading-content">
              <h3 className="item-heading">{subItem.heading}</h3>
              <button
                className="delete-button"
                onClick={() => onSubItemRemoved(item, subItem)}
              >
                <FaTrash />
              </button>
            </div>
            <p className="item-subheading">{subItem.subheading}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

interface EditableHeadingProps {
  item: ListItem;
  onItemUpdate: (originalItem: ListItem, updatedItem: ListItem) => void;
}
const EditableHeading = ({ item, onItemUpdate }: EditableHeadingProps) => {
  const [heading, setHeading] = useState(item.heading);
  const [subheading, setSubheading] = useState(item.subheading);
  const [editing, setEditing] = useState(false);

  const handleHeadingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeading(e.target.value);
  };

  const handleSubheadingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubheading(e.target.value);
  };

  const handleEditClick = () => {
    setEditing(!editing);
  };

  const handleUpdateClick = () => {
    const newItem = {
      ...item,
      heading: heading,
      subheading: subheading,
    };

    setEditing(false);
    onItemUpdate(item, newItem);
  };

  return (
    <div className="item-content">
      <div className="item-heading-content">
        {editing ? (
          <input
            className="item-heading"
            value={heading}
            onChange={handleHeadingChange}
          />
        ) : (
          <h3 className="item-heading">{item.heading}</h3>
        )}
        {editing ? (
          <button className="edit-button" onClick={handleUpdateClick}>
            <FaCheck />
          </button>
        ) : (
          <button className="edit-button" onClick={handleEditClick}>
            <FaPen />
          </button>
        )}
      </div>
      {editing ? (
        <input
          className="item-subheadinginput"
          value={subheading}
          onChange={handleSubheadingChange}
        />
      ) : (
        <p className="item-subheading">{item.subheading}</p>
      )}
    </div>
  );
};

type NonEditableHeadingProps = {
  item: ListItem;
};
const NonEditableHeading = ({ item }: NonEditableHeadingProps) => {
  return (
    <div className="item-content">
      <div className="item-heading-content">
        <h3 className="item-heading">{item.heading}</h3>
      </div>
      <p className="item-subheading">{item.subheading}</p>
    </div>
  );
};

interface EditableItemProps {
  item: ListItem;
  selected: boolean;
  uncollapsed: boolean;
  handleItemClick: (item: ListItem) => void;
  handleCollapsedItem: (item: ListItem) => void;
  onItemUpdate: (originalItem: ListItem, updatedItem: ListItem) => void;
}
const EditableItem = ({
  item,
  selected,
  uncollapsed,
  handleItemClick,
  handleCollapsedItem,
  onItemUpdate,
}: EditableItemProps) => {
  return (
    <li className="list-item">
      <input
        type="checkbox"
        className="item-checkbox"
        onClick={() => handleItemClick(item)}
        checked={selected}
        readOnly
      />
      {item.subItems.length > 0 ? (
        <EditableHeading item={item} onItemUpdate={onItemUpdate} />
      ) : (
        <NonEditableHeading item={item} />
      )}
      {item.subItems.length > 0 && (
        <button
          className="collapse-button"
          onClick={() => handleCollapsedItem(item)}
        >
          {uncollapsed ? <FaAngleDown /> : <FaAngleRight />}
        </button>
      )}
    </li>
  );
};

interface SelectableListProps {
  items: ListItem[];
  onSelect: (selectedItem: ListItem) => void;
  selectedItems: ListItem[];
  onItemUpdate: (originalItem: ListItem, updatedItem: ListItem) => void;
  onSubItemRemoved: (item: ListItem, subItem: SubItem) => void;
}
const CollapseSelectableList = ({
  items,
  onSelect,
  selectedItems,
  onItemUpdate,
  onSubItemRemoved,
}: SelectableListProps) => {
  const [uncollapsedItems, setUncollapsedItems] = useState<ListItem[]>([]);

  const handleToggleItem = (item: ListItem, itemList: ListItem[]) => {
    const selectedIndex = itemList.findIndex((i) => i.id === item.id);
    let newItemList = [...itemList];

    if (selectedIndex === -1) {
      newItemList.push(item);
    } else {
      newItemList.splice(selectedIndex, 1);
    }

    return newItemList;
  };

  const handleCollapsedItem = (item: ListItem) => {
    const newUncollapsedItems = handleToggleItem(item, uncollapsedItems);
    setUncollapsedItems(newUncollapsedItems);
  };

  return (
    <ul className="selectable-list">
      {items.map((item) => {
        const uncollapsed =
          uncollapsedItems.find((i) => i.id === item.id) !== undefined;
        const selected =
          selectedItems.find((i) => i.id === item.id) !== undefined;

        return (
          <div key={item.id}>
            <EditableItem
              item={item}
              selected={selected}
              uncollapsed={uncollapsed}
              handleItemClick={onSelect}
              handleCollapsedItem={handleCollapsedItem}
              onItemUpdate={onItemUpdate}
            />
            {uncollapsed && renderSubitems(item, onSubItemRemoved)}
          </div>
        );
      })}
    </ul>
  );
};

export default CollapseSelectableList;
