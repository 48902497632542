import { Paper, List } from "@mui/material";
import React from "react";
import { ReactComposable } from "../../utils/react-helper-types";

export const MetricsList: ReactComposable = ({ children, className }) => {
  return (
    <Paper elevation={8}>
      <List
        dense={true}
        disablePadding={true}
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
      >
        {children}
      </List>
    </Paper>
  );
};
