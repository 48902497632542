import React from "react";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import { useState } from "react";
import {
  isJavaRef,
  isUmlFile,
  JavaRef,
  UmlFile,
} from "../api/harvest-repo-api-types";
import { GraphVizEdge, GraphVizNode } from "./force-graph";
import { ForceGraph } from "./ForceGraph";
import { GraphVizSelectors } from "./GraphVizSelectors";
import {
  MetricsItem,
  MetricsList,
  MetricsTitle,
  MetricsItemDropdownList,
  stringToDropdownItem,
} from "./MetricsList";
import { MetricsItemCodeBlock } from "./MetricsList/MetricsItemCodeBlock";

const extractNodeMetrics = (node: GraphVizNode<JavaRef | UmlFile>) => {
  const typeToName = (type: string) => {
    switch (type) {
      case "JavaClassNode":
        return "Class";
      case "JavaInterfaceNode":
        return "Interface";
      case "JavaEnumNode":
        return "Enum";
      case "UmlFile":
        return "UML File";
      default:
        return "Unknown";
    }
  };

  if (isJavaRef(node.data)) {
    const javaRef = node.data;
    return (
      <>
        <MetricsItem name="Type" value={typeToName(javaRef.type)} />
        <MetricsItem name="Name" value={javaRef.data.name} />
      </>
    );
  } else if (isUmlFile(node.data)) {
    return (
      <>
        <MetricsItem name="Type" value="UML File" />
        <ListItem key={`micb-${node.data.path}`}>
          <MetricsItemCodeBlock
            name={node.data.path}
            code={node.data.raw_content}
            language={"plant-uml"}
          />
        </ListItem>
      </>
    );
  }

  return <></>;
};

const extractGraphMetrics = (
  nodes: GraphVizNode<JavaRef | UmlFile>[],
  edges: GraphVizEdge<number>[]
) => {
  const nodeData = nodes.map((node) => node.data);
  const javaRefs = nodeData.filter(isJavaRef);

  const numClasses = javaRefs.filter(
    (ref) => ref.type === "JavaClassNode"
  ).length;
  const numInterfaces = javaRefs.filter(
    (ref) => ref.type === "JavaInterfaceNode"
  ).length;
  const numUmlFiles = nodeData.filter(isUmlFile).length;

  // get number of edges for each diagram
  const diagrams = nodeData.filter(isUmlFile);
  const averageNumRefs =
    diagrams.reduce((acc, diagram) => {
      const numRefs = diagram.refs.length;
      return acc + numRefs;
    }, 0) / diagrams.length;

  // aggregate number of edges by sourceId
  const javaRefDiagramCounts = edges.reduce(
    (acc: { [key: number]: number }, edge) => {
      const sourceId = edge.source;
      //const sourceId = edge.sourceId;
      if (acc[sourceId]) {
        acc[sourceId] += 1;
      } else {
        acc[sourceId] = 1;
      }
      return acc;
    },
    []
  );
  const averageNumDiagrams =
    Object.values(javaRefDiagramCounts).reduce((acc, count) => acc + count, 0) /
    javaRefs.length;

  return (
    <>
      <MetricsItem name="Number of Classes" value={numClasses} />
      <MetricsItem name="Number of Interfaces" value={numInterfaces} />
      <MetricsItem name="Number of UML Files" value={numUmlFiles} />
      <MetricsItem
        name="Average Number of References in UML Diagrams"
        value={averageNumRefs.toFixed(2)}
      />
      <MetricsItem
        name="Average Number of Diagrams per Java Reference"
        value={averageNumDiagrams.toFixed(2)}
      />
    </>
  );
};

const umlFileToCodeBlock = (umlFile: UmlFile) => {
  return (
    <MetricsItemCodeBlock
      code={umlFile.raw_content}
      language="plant-uml"
      name={umlFile.path}
    />
  );
};

interface Selected {
  connected: GraphVizNode<JavaRef | UmlFile>[];
  edges: GraphVizEdge<number>[];
}

const SelectedMetrics = (selected: Selected) => {
  const nodes = selected.connected.map((n) => n.data);
  const javaRefs = nodes.filter(isJavaRef);
  const umlFiles = nodes.filter(isUmlFile);

  const javaItems = javaRefs.map((ref) => ref.data.name).sort();
  const umlItems = umlFiles.map(umlFileToCodeBlock);

  return (
    <>
      <MetricsList>
        <MetricsTitle title="Selected Nodes" />
        <MetricsItemDropdownList
          title="Java Nodes"
          items={javaItems.map(stringToDropdownItem)}
        />
        <MetricsItemDropdownList title="UML Nodes" items={umlItems} />
      </MetricsList>
    </>
  );
};

interface TooltipProps {
  highlightedNode?: GraphVizNode<JavaRef | UmlFile>;
  selected?: Selected;
}
const GraphVisualizerTooltip = ({
  highlightedNode,
  selected,
}: TooltipProps) => {
  const metrics = highlightedNode ? extractNodeMetrics(highlightedNode) : [];
  const selectedMetrics = selected ? SelectedMetrics(selected) : <></>;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item />
      <Grid item>
        <MetricsList>
          <MetricsTitle title="Highlighted Node" />
          {metrics}
        </MetricsList>
      </Grid>
      <Grid item>{selectedMetrics}</Grid>
    </Grid>
  );
};

interface Props {
  nodes: GraphVizNode<JavaRef | UmlFile>[];
  edges: GraphVizEdge<number>[];
  showUnconnected: boolean;
  onChangeShowUnconnected: (showUnconnected: boolean) => void;
  showInterfaces: boolean;
  onChangeShowInterfaces: (showInterfaces: boolean) => void;
  showEnums: boolean;
  onShowEnums: (showEnums: boolean) => void;
}
export const GraphVisualizerCard = ({
  nodes,
  edges,
  showUnconnected,
  onChangeShowUnconnected,
  showInterfaces,
  onChangeShowInterfaces,
  showEnums,
  onShowEnums,
}: Props) => {
  const [highlightedNode, setHighlightedNode] =
    useState<GraphVizNode<JavaRef | UmlFile>>();
  const [selected, setSelected] = useState<Selected>();

  const onSelected = (
    connected: GraphVizNode<JavaRef | UmlFile>[],
    edges: GraphVizEdge<number>[]
  ) => {
    setSelected({ connected, edges });
  };

  const umlFiles = nodes
    .map((n) => n.data)
    .filter(isUmlFile)
    .sort((a, b) => a.path.localeCompare(b.path))
    .map(umlFileToCodeBlock);

  return (
    <Paper elevation={4}>
      <Box p={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">UML Java Class Graph</Typography>
          </Grid>
          <Grid item xs={8}>
            <ForceGraph
              nodesData={nodes}
              linksData={edges}
              width={900}
              height={720}
              onMouseOver={(node) => setHighlightedNode(node)}
              onSelected={onSelected}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphVizSelectors
              onChangeShowUnconnected={onChangeShowUnconnected}
              showUnconnected={showUnconnected}
              onChangeShowInterfaces={onChangeShowInterfaces}
              showInterfaces={showInterfaces}
              onChangeShowEnums={onShowEnums}
              showEnums={showEnums}
            />
            <MetricsList>
              <MetricsTitle title="Graph Metrics" />
              {extractGraphMetrics(nodes, edges)}
              <MetricsItemDropdownList title="UML Files" items={umlFiles} />
            </MetricsList>
            <GraphVisualizerTooltip
              highlightedNode={highlightedNode}
              selected={selected}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
