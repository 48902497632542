import {
  Paper,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { FileHistoryViewer } from "./FileHistoryViewer";
import { useState } from "react";
import { CoverageMap, FileName } from "../api/harvest-repo-api-types";

interface Props {
  files: FileName[];
  onFileSelected: (fileName: string, version: number) => void;
  coverageMap?: CoverageMap;
}
export const FileHistoryCard = ({
  files,
  onFileSelected,
  coverageMap,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [version, setVersion] = useState(0);
  const [versions, setVersions] = useState<number[]>([]);

  const fileVersionCountMap = new Map(files.map((f) => [f.name, f.count]));

  return (
    <Paper elevation={4}>
      <Box p={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">File History</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="autocomplete-bar"
              options={files.map((f) => f.name)}
              freeSolo
              onInputChange={(e, value) => {
                const versionCount = fileVersionCountMap.get(value) || 0;
                setVersions(Array.from({ length: versionCount }, (_, i) => i));
                return value ? setSearchTerm(value) : setSearchTerm("");
              }}
              renderInput={(params) => (
                <TextField {...params} label="Java Source Name" />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              id="version-select"
              value={version}
              onChange={(e) => {
                setVersion(e.target.value as number);
              }}
            >
              {versions.map((v) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{ height: "100%" }}
              variant="contained"
              onClick={() => onFileSelected(searchTerm, version)}
            >
              Select File
            </Button>
          </Grid>
          <Grid item xs={12}>
            {coverageMap && <FileHistoryViewer coverageMap={coverageMap} />}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
