import React, { useCallback, useEffect, useState } from "react";
import CollapseSelectableList, {
  SubItem,
  ListItem,
} from "../components/CollapseSelectableList";
import StickyButton from "../components/StickyButton";
import { AuthorCleanerApi } from "../api/author-cleaner-api";

import "./AuthorCleaner.css";
import { getAuthorMergeSuggestions } from "../utils/author-merge-suggester";
import { RepoSelectBar } from "../components/RepoSelectBar";
import { RepoInfo } from "../api/harvest-repo-api-types";
import {
  Author,
  AuthorCleaned,
  isCleanedAuthor,
} from "../api/author-cleaner-api-types";
import { Suggestion, SuggestionModal } from "../components/SuggestionModal";
import { MenuItem, Select } from "@mui/material";
import { HarvestRepoApi } from "../api/harvest-repo-api";
import { ProgressModal } from "../components/ProgressModal";

type SortAuthorBy = "name" | "email";

export function CommitCleaner() {
  const [selectedItems, setSelectedItems] = useState<ListItem[]>([]);
  const [items, setItems] = useState<ListItem[]>([]);
  const [sortBy, setSortBy] = useState<SortAuthorBy>("name");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [repoInfo, setRepoInfo] = useState<RepoInfo | null>(null);
  const [numRawAuthors, setNumRawAuthors] = useState(0);
  const [numAuthorsWithAliases, setNumAuthorsWithAliases] = useState(0);
  const [numAliases, setNumAliases] = useState(0);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState("");

  const sortItem = (a: ListItem, b: ListItem) =>
    sortBy === "name"
      ? a.heading.localeCompare(b.heading)
      : a.subheading.localeCompare(b.subheading);

  const updateItems = async (repoInfo: RepoInfo) => {
    const authorMap = await AuthorCleanerApi.getAuthorsMap(repoInfo.name);
    const cleanedAuthors = await AuthorCleanerApi.getAuthorsCleaned(
      repoInfo.name
    );

    const authorIdsWithAliases = new Set(
      cleanedAuthors.flatMap((author) => author.author_raw_ids)
    );
    const authorsWithoutAliases = [...authorMap.values()].filter(
      (author) => !authorIdsWithAliases.has(author.id)
    );

    const items = [
      ...authorsWithoutAliases.map(authorToItem(authorMap)),
      ...cleanedAuthors.map(authorToItem(authorMap)),
    ];

    const sortedItems = items.sort(sortItem);

    setItems(sortedItems);
    setNumRawAuthors(authorMap.size);
    setNumAliases(authorIdsWithAliases.size);
    setNumAuthorsWithAliases(cleanedAuthors.length);

    console.log("sortedItems", sortedItems);

    return sortedItems;
  };

  const init = useCallback(
    async (repoInfo: RepoInfo) => {
      const items = await updateItems(repoInfo);

      // show suggestions for raw authors that haven't already been added to aliases
      //const authorsWithoutAliases: Author[] = items
      //  .filter((a) => a.id.split("-")[0] === "raw")
      //  .map((item) => ({
      //    id: parseInt(item.id.split("-")[1]),
      //    name: item.heading,
      //    email: item.subheading,
      //  }));
      //showSuggestions(authorsWithoutAliases);
    },
    [sortBy] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!repoInfo) {
      return;
    }

    init(repoInfo);
  }, [repoInfo, init]);

  const authorToSubItem = (author: Author) => ({
    id: author.id,
    heading: author.name,
    subheading: author.email,
  });

  const getAuthor = (authorMap: Map<number, Author>) => (id: number) => {
    const author = authorMap.get(id);
    if (!author) {
      throw Error("Author does not exist");
    }
    return author;
  };

  const authorToItem =
    (authorMap: Map<number, Author>) => (author: Author | AuthorCleaned) => {
      return {
        id: `${isCleanedAuthor(author) ? "cleaned" : "raw"}-${author.id}`,
        heading: author.name,
        subheading: author.email,
        subItems: isCleanedAuthor(author)
          ? author.author_raw_ids.map(getAuthor(authorMap)).map(authorToSubItem)
          : [],
      };
    };

  const showSuggestions = (authors: Author[]) => {
    const authorSuggestions = getAuthorMergeSuggestions(authors);

    const convertedSuggestions: Suggestion[] = authorSuggestions.map(
      (authorSuggestion) => ({
        id: authorSuggestion.author.id,
        title: authorSuggestion.author.name,
        subTitle: authorSuggestion.author.email,
        matches: authorSuggestion.authorsToMerge.map((author) => ({
          id: author.id,
          title: author.name,
          subTitle: author.email,
        })),
      })
    );

    setSuggestions(convertedSuggestions);

    if (convertedSuggestions.length > 0) {
      setShowSuggestionModal(true);
    }
  };

  const suggestionToAuthor = (suggestion: {
    id: number;
    title: string;
    subTitle: string;
  }): Author => ({
    id: suggestion.id,
    name: suggestion.title,
    email: suggestion.subTitle,
  });

  const addCleanAuthor = async (cleanAuthor: Author, authors: Author[]) => {
    const cleanAuthorId = await AuthorCleanerApi.addCleanAuthor(
      repoInfo!.name,
      cleanAuthor
    );

    authors.forEach((author) => {
      AuthorCleanerApi.addAuthorAlias(cleanAuthorId, author.id);
    });
  };

  const handleSuggestionAccepted = async (suggestion: Suggestion) => {
    if (!repoInfo) {
      return;
    }

    const cleanAuthor: Author = suggestionToAuthor(suggestion);
    const authors: Author[] = suggestion.matches.map(suggestionToAuthor);

    await addCleanAuthor(cleanAuthor, authors);

    suggestions.splice(suggestions.indexOf(suggestion), 1);

    updateItems(repoInfo);
    setSuggestions([...suggestions]);
  };

  const handleRepoChange = async (repos: string[]) => {
    if (repos.length === 1) {
      const repoInfo = await HarvestRepoApi.getRepoInfo(repos[0]);
      setRepoInfo(repoInfo);
      return;
    } else if (repos.length > 1) {
      setShowProgressModal(true);

      for (const repo of repos) {
        setProgress(`Updating ${repo}...`);

        const repoInfo = await HarvestRepoApi.getRepoInfo(repo);
        const items = await updateItems(repoInfo);
        const authors: Author[] = items.map((item) => ({
          id: parseInt(item.id.split("-")[1]),
          name: item.heading,
          email: item.subheading,
        }));
        const authorSuggestions = getAuthorMergeSuggestions(authors);

        for (const suggestion of authorSuggestions) {
          const cleanAuthor: Author = suggestion.author;
          const authors: Author[] = suggestion.authorsToMerge;

          const cleanAuthorId = await AuthorCleanerApi.addCleanAuthor(
            repoInfo!.name,
            cleanAuthor
          );

          authors.forEach((author) => {
            AuthorCleanerApi.addAuthorAlias(cleanAuthorId, author.id);
          });
        }
      }
    }
  };

  const handleItemUpdate = async (
    originalItem: ListItem,
    updatedItem: ListItem
  ) => {
    const author = itemToAuthor(updatedItem);
    await AuthorCleanerApi.updateAuthorAlias(
      author.id,
      author.name,
      author.email
    );
    repoInfo && updateItems(repoInfo);
  };

  const handleSelect = (selectedItem: ListItem) => {
    const newSelectedItems = [...selectedItems];
    const index = newSelectedItems.findIndex((i) => i.id === selectedItem.id);

    if (index === -1) {
      newSelectedItems.push(selectedItem);
    } else {
      newSelectedItems.splice(index, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const handleSubItemRemoved = async (item: ListItem, subItem: SubItem) => {
    const author = itemToAuthor(item);
    const subAuthor = subItemToAuthor(subItem);
    await AuthorCleanerApi.removeAlias(author.id, subAuthor.id);
    repoInfo && updateItems(repoInfo);
  };

  type MergeCaseType = "allRaw" | "oneCleaned" | "multipleCleaned";

  const itemToAuthor = (item: ListItem): Author => ({
    id: parseInt(item.id.split("-")[1]),
    name: item.heading,
    email: item.subheading,
  });

  const subItemToAuthor = (item: SubItem): Author => ({
    id: item.id,
    name: item.heading,
    email: item.subheading,
  });

  const handleAllRawMerge = async (itemsToMerge: ListItem[]) => {
    const firstAuthor = itemsToMerge[0];
    const author = itemToAuthor(firstAuthor);
    const authors = itemsToMerge.map(itemToAuthor);

    await addCleanAuthor(author, authors);

    setSelectedItems([]);
    repoInfo && updateItems(repoInfo);
  };

  const handleOneCleanedMerge = async (itemsToMerge: ListItem[]) => {
    const cleanedAuthor = itemsToMerge.find((item) => item.subItems.length > 0);
    const rawAuthors = itemsToMerge.filter(
      (item) => item.subItems.length === 0
    );

    if (!cleanedAuthor) {
      throw Error("No cleaned author found, but at least one expected");
    }

    const cleanAuthor = itemToAuthor(cleanedAuthor);
    const authors = rawAuthors.map(itemToAuthor);
    authors.forEach((author) => {
      AuthorCleanerApi.addAuthorAlias(cleanAuthor.id, author.id);
    });

    setSelectedItems([]);
    repoInfo && updateItems(repoInfo);
  };

  const getMergeCaseType = (itemsToMerge: ListItem[]): MergeCaseType => {
    const cleanedAuthors = itemsToMerge.filter(
      (item) => item.subItems.length > 0
    );

    return cleanedAuthors.length === 0
      ? "allRaw"
      : cleanedAuthors.length === 1
      ? "oneCleaned"
      : "multipleCleaned";
  };

  const handleMerge = async () => {
    const caseType: MergeCaseType = getMergeCaseType(selectedItems);

    // 3 cases:
    switch (caseType) {
      // 1. All selected authors are raw authors (no subItems).
      //    In this case we create a new cleaned author using the info from the best selected author, but adding all authors as aliases.
      case "allRaw":
        await handleAllRawMerge(selectedItems);
        break;

      // 2. 1 of the selected authors is a cleaned author, the rest are raw authors.
      //    In this case we add all raw authors as aliases to the cleaned author.
      case "oneCleaned":
        await handleOneCleanedMerge(selectedItems);
        break;

      // 3. There are multiple cleaned authors selected. In this case we have to pick one of the
      //    clean authors as the main, move all raw authors to those clean authors, and delete the other
      //    clean authors.j
    }
  };

  return (
    <div className="commit-cleaner">
      <div className="grid-container">
        <div className="repo-search-bar">
          <div className="sort-by-container">
            <h4>Sort Repos By</h4>
            <Select
              className="sort-by-selector"
              value={sortBy}
              onChange={(event) =>
                ["name", "email"].includes(event.target.value)
                  ? setSortBy(event.target.value as SortAuthorBy)
                  : null
              }
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </div>
          <RepoSelectBar onReposSelected={handleRepoChange} />
        </div>
        <div className="collapse-selector">
          {numRawAuthors > 0 && (
            <div className="commit-authors-section">
              <h2 className="collapse-selector-header">Commit Authors</h2>
              <div className="commit-authors-stats">
                <div className="stat-item">
                  Raw Authors <span>{numRawAuthors}</span>
                </div>
                <div className="stat-item">
                  Real Authors{" "}
                  <span>
                    {numRawAuthors - numAliases + numAuthorsWithAliases}
                  </span>
                </div>
                <div className="stat-item">
                  Authors with Aliases <span>{numAuthorsWithAliases}</span>
                </div>
                <div className="stat-item">
                  Number of Aliases <span>{numAliases}</span>
                </div>
              </div>
            </div>
          )}
          <CollapseSelectableList
            items={items}
            selectedItems={selectedItems}
            onSelect={handleSelect}
            onSubItemRemoved={handleSubItemRemoved}
            onItemUpdate={handleItemUpdate}
          />
        </div>
        <StickyButton
          buttonContent={"Merge Items"}
          showButton={selectedItems.length > 1}
          onClick={handleMerge}
        />
      </div>
      <SuggestionModal
        open={showSuggestionModal}
        onClose={() => setShowSuggestionModal(false)}
        suggestions={suggestions}
        onSuggestionAccepted={handleSuggestionAccepted}
      />
      <ProgressModal
        open={showProgressModal}
        onClose={() => setShowProgressModal(false)}
        title={"Cleaning Authors"}
        progress={progress}
      />
    </div>
  );
}
