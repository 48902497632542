import { useEffect, useState } from "react";
import { HarvestRepoApi } from "../api/harvest-repo-api";
import { CommitView, RepoInfo } from "../api/harvest-repo-api-types";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ErrorModal from "./ErrorModal";

import "./RepoSearchBar.css";

interface Props {
  onRepoChange: (repoInfo: RepoInfo) => void;
  commitView?: CommitView;
  onCommitViewChange?: (commitView: CommitView) => void;
}
export function RepoSearchBar({
  onRepoChange,
  commitView,
  onCommitViewChange,
}: Props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [repoList, setRepoList] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const handleSearch = () => {
    if (!repoList.includes(searchTerm)) {
      setError(`Only repos ${repoList.join(", ")} are supported`);
      return;
    }

    HarvestRepoApi.getRepoInfo(searchTerm).then((data) => {
      onRepoChange(data);
    });
  };

  useEffect(() => {
    HarvestRepoApi.getRepoList().then((data) => {
      setRepoList(data);
    });
  }, []);

  return (
    <div className="container">
      <Autocomplete
        id="autocomplete-bar"
        options={repoList}
        freeSolo
        onInputChange={(e, value) =>
          value ? setSearchTerm(value) : setSearchTerm("")
        }
        renderInput={(params) => <TextField {...params} label="owner/repo" />}
      />
      {commitView && onCommitViewChange && (
        <Select
          value={commitView}
          label="Commit View"
          onChange={(event) =>
            ["commits", "releases"].includes(event.target.value)
              ? onCommitViewChange(event.target.value as CommitView)
              : null
          }
        >
          <MenuItem value="commits">Commit View</MenuItem>
          <MenuItem value="releases">Release View</MenuItem>
        </Select>
      )}
      <Button
        id="search-button"
        color="primary"
        aria-label="upload picture"
        component="label"
        onClick={handleSearch}
      >
        Visualize
      </Button>
      <ErrorModal errorMessage={error} onClose={() => setError("")} />
    </div>
  );
}
