import {
  AnalyzedProj,
  JavaRef,
  RefEdge,
  UmlFile,
} from "../api/harvest-repo-api-types";
import { GraphVizNode, GraphVizEdge } from "../components/force-graph";
import { getRefs } from "./java-proj-functions";

export const buildClassEdgeMap = (
  analyzedProj: AnalyzedProj
): Map<number, RefEdge[]> => {
  const edgeMap = new Map<number, RefEdge[]>();
  analyzedProj.ref_edges.forEach((edge) => {
    const sourceId = edge.source.id;
    const targetId = edge.target.id;
    if (!edgeMap.has(sourceId)) {
      edgeMap.set(sourceId, []);
    }
    if (!edgeMap.has(targetId)) {
      edgeMap.set(targetId, []);
    }
    edgeMap.get(sourceId)?.push(edge);
    edgeMap.get(targetId)?.push(edge);
  });
  return edgeMap;
};

const getNodes = (analyzedProj: AnalyzedProj) => {
  const javaNodes = analyzedProj.java_proj.children
    .flatMap(getRefs)
    .map(javaRef2Node);
  const umlNodes = analyzedProj.uml_proj.diagrams.map(umlFile2Node);

  return [...javaNodes, ...umlNodes];
};

const getNodesWithEdges = (analyzedProj: AnalyzedProj) => {
  return analyzedProj
    ? analyzedProj.r2d_edges
        .flatMap<GraphVizNode<JavaRef | UmlFile>>((edge) => [
          javaRef2Node(edge.source),
          umlFile2Node(edge.target),
        ])
        .reduce((acc, node) => {
          if (!acc.find((n) => n.id === node.id)) {
            acc.push(node);
          }
          return acc;
        }, [] as GraphVizNode<JavaRef | UmlFile>[])
    : [];
};

const javaRef2Node = (ref: JavaRef): GraphVizNode<JavaRef> => ({
  id: ref.id,
  color: "#2596be",
  size: 1,
  shape:
    ref.type === "JavaClassNode"
      ? ref.data.is_abstract
        ? "diamond"
        : "circle"
      : ref.type === "JavaEnumNode"
      ? "+"
      : "triangle",
  label: ref.data.name,
  data: ref,
});

const umlFile2Node = (file: UmlFile): GraphVizNode<UmlFile> => ({
  id: file.id,
  color: "rgb(0, 255, 0)",
  size: 2,
  shape: "rect",
  label: file.path.split("/").at(-1) ?? "",
  data: file,
});

export const getClass2DiagramNodes = (
  analyzedProj: AnalyzedProj
): GraphVizNode<JavaRef | UmlFile>[] => {
  const nodesWithEdges = getNodesWithEdges(analyzedProj);
  const nodeWithEdgesIds = new Set(nodesWithEdges.map((n) => n.id));
  const nodesWithoutEdges = getNodes(analyzedProj).filter(
    (n) => !nodeWithEdgesIds.has(n.id)
  );

  return [...nodesWithEdges, ...nodesWithoutEdges];
};

export const getClass2DiagramEdges = (
  analyzedProj: AnalyzedProj
): GraphVizEdge<number>[] => {
  return analyzedProj
    ? analyzedProj.r2d_edges.map((edge) => ({
        id: edge.id,
        source: edge.source.id,
        target: edge.target.id,
        data: 0,
      }))
    : [];
};
