import { Paper, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useState, useCallback } from "react";
import { RefEdge, EdgeMetric } from "../api/harvest-repo-api-types";
import { JavaNode } from "../utils/circle-pack-data-converter";
import { Aggregation, calculateEdgeMetrics } from "../utils/metrics-calculator";
import { AggregationTypeSelector } from "./AggregationTypeSelector";
import { CirclePack } from "./CirclePack";
import { JavaCirclePackToolTip } from "./JavaCirclePackToolTip";
import { MetricTypeSelector } from "./MetricTypeSelector";

interface Props {
  javaTree: JavaNode;
  edgeMap: Map<number, RefEdge[]>;
  edgeMetric: EdgeMetric;
  onMetricTypeChange: (metric: EdgeMetric) => void;
  umlRefs: string[];
}
export const CirclePackCard = ({
  javaTree,
  edgeMap,
  edgeMetric,
  onMetricTypeChange,
  umlRefs,
}: Props) => {
  const [selectedNode, setSelectedNode] = useState<JavaNode>();
  const circlePackMouseOver = useCallback((node: JavaNode) => {
    setSelectedNode(node);
  }, []);

  const [aggregation, setAggregation] = useState<Aggregation>("avg");
  const onAggregationTypeChange = (aggregation: Aggregation) => {
    setAggregation(aggregation);
  };

  const leafIntensity = useCallback(
    (node: JavaNode) => {
      const leafNode =
        node.data !== "root" && node.data.type !== "JavaPackNode"
          ? node.data
          : "None";

      if (leafNode === "None") return "None";

      const edges = edgeMap?.get(leafNode.id);
      const intensity = edges
        ? calculateEdgeMetrics(edgeMetric)(aggregation)(edges)
        : "None";

      return intensity;
    },
    [edgeMap, edgeMetric, aggregation]
  );

  return (
    <Paper elevation={4}>
      <Box p={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Java Package Visualizer</Typography>
          </Grid>
          <Grid item xs={8}>
            {javaTree && (
              <>
                <CirclePack
                  data={javaTree}
                  width={720}
                  height={720}
                  onMouseOver={circlePackMouseOver}
                  leafIntensity={leafIntensity}
                />
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              spacing={2}
              direction="column"
              flexDirection="column"
            >
              <Grid item>
                <Paper elevation={4}>
                  <Box p={1.5}>
                    <Typography variant="h6" align="left">
                      Metric Selectors
                    </Typography>
                    <AggregationTypeSelector
                      onChange={onAggregationTypeChange}
                    />
                    <MetricTypeSelector
                      edgeMetric={edgeMetric}
                      onChange={onMetricTypeChange}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item>
                {selectedNode && (
                  <JavaCirclePackToolTip
                    node={selectedNode}
                    aggregationType={aggregation}
                    edgeMap={edgeMap}
                    metricsType={edgeMetric}
                    umlRefs={umlRefs}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
