import { z } from "zod";

const authorSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
});
export type Author = z.infer<typeof authorSchema>;

export const getRawAuthorsSchema = z.object({
  authors: z.array(authorSchema),
});
export type RawAuthors = z.infer<typeof getRawAuthorsSchema>;

const authorCleanedSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  author_raw_ids: z.array(z.number()),
});
export type AuthorCleaned = z.infer<typeof authorCleanedSchema>;
export function isCleanedAuthor(
  author: Author | AuthorCleaned
): author is AuthorCleaned {
  return (author as AuthorCleaned).author_raw_ids !== undefined;
}

export const getCleanedAuthorsSchema = z.object({
  authors: z.array(authorCleanedSchema),
});

export const cleanedAuthorsAddResponse = z.object({
  author_id: z.number(),
});
