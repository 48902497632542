import { useState } from "react";
import { Button, TextField } from "@mui/material";

import "./RepoSearchBar.css";

interface Props {
  onReposSelected: (repos: string[]) => void;
}
export function RepoSelectBar({ onReposSelected }: Props) {
  const [searchTerm, setSearchTerm] = useState<string[]>([]);

  const handleSearch = () => {
    onReposSelected(searchTerm);
  };

  return (
    <div className="container">
      <TextField
        id="autocomplete-bar"
        onChange={(event) => {
          const repos = event.target.value.split(",");
          setSearchTerm(repos);
        }}
      />
      <Button
        id="search-button"
        color="primary"
        aria-label="upload picture"
        component="label"
        onClick={handleSearch}
      >
        Visualize
      </Button>
    </div>
  );
}
