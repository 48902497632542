import axios from "axios";
import {
  getRepoListSchema,
  getRepoInfoSchema,
  coverageHistorySchema,
  javaProjHistory,
  releaseSchema,
  CommitView,
  fileNamesSchema,
  coverageMapSchema,
} from "./harvest-repo-api-types";

const harvestRepoApiUrl = process.env.REACT_APP_HARVEST_FLASK_API_URL;
console.log(`harvest repo url: ${harvestRepoApiUrl}`);

export const HarvestRepoApi = {
  getRepoList: async function (): Promise<string[]> {
    console.log(`getting repo list from ${harvestRepoApiUrl}/repos`);
    const response = await axios.get(`${harvestRepoApiUrl}/repos`);

    return getRepoListSchema.parse(response.data);
  },

  getRepoInfo: async function (repoName: string) {
    console.log(
      `getting repo list from ${harvestRepoApiUrl}/repos/${repoName}/info`
    );
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/info`
    );

    return getRepoInfoSchema.parse(response.data);
  },

  getCommit: async function (
    repoName: string,
    committish: string,
    view: CommitView
  ) {
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/commit/${committish}`,
      { params: { view: view } }
    );

    return releaseSchema.parse(response.data);
  },

  getCoverageHistory: async function (repoName: string, view: CommitView) {
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/coverage-history`,
      { params: { view: view } }
    );

    return coverageHistorySchema.parse(response.data);
  },

  getJavaProjHistory: async function (repoName: string, view: CommitView) {
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/java-metrics`,
      { params: { view: view } }
    );

    return javaProjHistory.parse(response.data);
  },

  getFiles: async function (
    repoName: string,
    view: CommitView,
    extension: string
  ) {
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/file-names`,
      { params: { view: view, ext: extension } }
    );

    return fileNamesSchema.parse(response.data);
  },

  getCoverageMap: async function (
    repoName: string,
    view: CommitView,
    fileName: string,
    version: number
  ) {
    const response = await axios.get(
      `${harvestRepoApiUrl}/repos/${repoName}/coverage-map`,
      { params: { view: view, file_name: fileName, version: version } }
    );

    return coverageMapSchema.parse(response.data);
  },
};
