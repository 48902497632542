import { Route, Routes } from "react-router-dom";
import { Visualizations } from "./pages/Visualizations";
import { CommitCleaner } from "./pages/AuthorCleaner";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Visualizations />} />
        <Route path="/aviz" element={<CommitCleaner />} />
      </Routes>
    </>
  );
}

export default App;
