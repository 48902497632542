import React from "react";
import ReactEcharts from "echarts-for-react";
import type { EChartsOption } from "echarts";
import {
  CoverageHistory,
  JavaProjHistory,
} from "../api/harvest-repo-api-types";

const toDate = (x: string) => {
  const date = new Date(x);
  const offset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() - offset * 60 * 1000);
  return offsetDate;
};

const formattedDate = (x: string) => {
  const date = toDate(x);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const toHtml = (
  title: string,
  subtitle: string,
  values: [string, string][]
) => {
  return `<div><b>${title} (${subtitle})</b><br><br>${values
    .map((x) => `<b>${x[0]}</b> ${x[1]}<br>`)
    .join("")}</div>
  `;
};

type OnClickData = {
  data: {
    create: Date;
    formatted_created: string;
    tag: string;
    coverage: number;
    num_classes: number;
    java_parsed_correctly: number;
    java_parsed_incorrectly: number;
  };
};

interface Props {
  coverageHistory: CoverageHistory;
  javaProjHistory: JavaProjHistory;
  onSelectCommit: (commit: string) => void;
}
export const LineChart = ({
  coverageHistory,
  javaProjHistory,
  onSelectCommit,
}: Props) => {
  const zip = <T, U>(a: T[], b: U[]): [T, U][] => a.map((k, i) => [k, b[i]]);

  const dataSource = zip(coverageHistory, javaProjHistory).map(([a, b]) => {
    return {
      created: toDate(a.created),
      formatted_created: formattedDate(a.created),
      tag: a.committish,
      coverage: a.coverage,
      num_classes: b.java_proj_data.num_classes,
      java_parsed_correctly:
        b.java_proj_data.java_parse_confidence.successful_files,
      java_parsed_incorrectly:
        b.java_proj_data.java_parse_confidence.failed_files,
    };
  });

  // sort by date
  const sortedDataSource = dataSource.sort(
    (a, b) => a.created.getTime() - b.created.getTime()
  );

  const onEvents = {
    click: (event: OnClickData) => onSelectCommit(event.data.tag),
  };

  const option: EChartsOption = {
    // add progressive loading
    title: {
      text: "",
      left: "left",
    },
    legend: {
      data: ["Coverage", "Number of Classes"],
    },
    dataset: {
      source: sortedDataSource,
      dimensions: [
        { name: "created", displayName: "Creation Date", type: "time" },
        { name: "tag", displayName: "Release Tag" },
        "coverage",
        "num_classes",
        "java_parsed_correctly",
        "java_parsed_incorrectly",
      ],
    },
    xAxis: {
      type: "time",
    },
    yAxis: [
      {
        name: "Coverage",
        type: "value",
        min: 0,
        max: 1.0,
      },
      {
        name: "Number of Classes",
        nameLocation: "end",
        alignTicks: true,
        type: "value",
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const data = params[0].data;
        return toHtml(data.formatted_created, data.tag, [
          ["Coverage", data.coverage.toFixed(2)],
          ["Number of Classes", data.num_classes],
          ["Java Parsed Correctly", data.java_parsed_correctly],
          ["Java Parsed Incorrectly", data.java_parsed_incorrectly],
        ]);
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        xAxisIndex: [0],
      },
      {
        type: "slider",
        show: true,
        yAxisIndex: [0],
      },
    ],
    series: [
      {
        name: "Coverage",
        type: "line",
        progressive: 100,
        progressiveThreshold: 100,
        encode: {
          x: "created",
          y: "coverage",
          tooltip: [
            "tag",
            "coverage",
            "num_classes",
            "java_parsed_correctly",
            "java_parsed_incorrectly",
          ],
        },
        yAxisIndex: 0,
      },
      {
        name: "Number of Classes",
        type: "line",
        progressive: 100,
        progressiveThreshold: 100,
        encode: { x: "created", y: "num_classes" },
        yAxisIndex: 1,
      },
    ],
  };

  return <ReactEcharts option={option} onEvents={onEvents} />;
};
