import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { HarvestRepoApi } from "../api/harvest-repo-api";
import {
  RepoInfo,
  AnalyzedProj,
  RefEdge,
  CoverageHistory,
  JavaProjHistory,
  ReleaseInfo,
  JavaRef,
  UmlFile,
  EdgeMetric,
  edgeMetrics,
  CommitView,
  FileName,
  CoverageMap,
  Release,
  isUmlFile,
  isJavaRef,
} from "../api/harvest-repo-api-types";
import { CirclePackCard } from "../components/CirclePackCard";
import { FileHistoryCard } from "../components/FileHistoryCard";
import { GraphVisualizerCard } from "../components/GraphVisualizerCard";
import HistoryVisualizerCard from "../components/HistoryVisualizerCard";
import { ReleaseMetricsCard } from "../components/ReleaseMetricsCard";
import { RepoSelectorCard } from "../components/RepoSelectorCard";
import { GraphVizNode, GraphVizEdge } from "../components/force-graph";
import {
  buildClassEdgeMap,
  getClass2DiagramNodes,
  getClass2DiagramEdges,
} from "../utils/analyzed-project-map-builder";
import { JavaNode, javaProj2Node } from "../utils/circle-pack-data-converter";
import LoadingModal from "../components/LoadingModal";

import "./Visualizations.css";

export function Visualizations() {
  const [repoInfo, setRepoInfo] = useState<RepoInfo>();
  const [analyzedProj, setAnalyzedProj] = useState<AnalyzedProj>();
  const [javaTree, setJavaTree] = useState<JavaNode>();
  const [umlRefs, setUmlRefs] = useState<string[]>([]);
  const [edgeMap, setEdgeMap] = useState<Map<number, RefEdge[]>>();
  const [coverageHistory, setCoverageHistory] = useState<CoverageHistory>();
  const [javaProjHistory, setJavaProjHistory] = useState<JavaProjHistory>();
  const [releaseInfo, setReleaseInfo] = useState<ReleaseInfo>();
  const [initialized, setInitialized] = useState(false);
  const [graphNodes, setGraphNodes] = useState<
    GraphVizNode<JavaRef | UmlFile>[]
  >([]);
  const [graphEdges, setGraphEdges] = useState<GraphVizEdge<number>[]>([]);
  const [edgeMetric, setEdgeMetric] = useState<EdgeMetric>(edgeMetrics[0]);
  const [showInterfaces, setShowInterfaces] = useState(true);
  const [showEnums, setShowEnums] = useState(true);
  const [showUnconnected, setShowUnconnected] = useState(true);
  const [commitView, setCommitView] = useState<CommitView>("releases");
  const [javaFiles, setJavaFiles] = useState<FileName[]>([]);
  const [coverageMap, setCoverageMap] = useState<CoverageMap>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if there is no repository info then we can't initialize. If we're already initialized then we don't need to do anything.
    if (!repoInfo || initialized) {
      return;
    }

    const initializeNewRepo = async (repoInfo: RepoInfo) => {
      const coverageHistoryResponse = await HarvestRepoApi.getCoverageHistory(
        repoInfo.name,
        commitView
      );
      setCoverageHistory(coverageHistoryResponse);

      const javaProjHistory = await HarvestRepoApi.getJavaProjHistory(
        repoInfo.name,
        commitView
      );
      setJavaProjHistory(javaProjHistory);

      const javaFilesResponse = await HarvestRepoApi.getFiles(
        repoInfo.name,
        commitView,
        "java"
      );
      setJavaFiles(javaFilesResponse);

      const initialRelease = coverageHistoryResponse.at(-1)?.committish;
      if (!initialRelease) throw new Error("No initial release found");

      const releasesInfo = await HarvestRepoApi.getCommit(
        repoInfo.name,
        initialRelease,
        commitView
      );
      updateRelease(releasesInfo);

      setInitialized(true);
      setLoading(false);
    };

    initializeNewRepo(repoInfo).catch(console.log);
  }, [repoInfo, initialized, commitView]);

  const updateRelease = (release: Release) => {
    setAnalyzedProj(release.analyzed_project);

    setEdgeMap(buildClassEdgeMap(release.analyzed_project));

    const node = javaProj2Node(release.analyzed_project.java_proj);
    setJavaTree(node);

    setUmlRefs([
      ...new Set(
        release.analyzed_project.uml_proj.children.map((c) => c.data.name)
      ),
    ]);

    setReleaseInfo({
      ...release.info,
      html_url: commitUrl(commitView, release.info.tag_name),
    });

    setShowInterfaces(true);
    setShowEnums(true);
    setShowUnconnected(true);
    setGraphNodes(getClass2DiagramNodes(release.analyzed_project));
    setGraphEdges(getClass2DiagramEdges(release.analyzed_project));
  };

  const onRepoChange = (repoInfo: RepoInfo) => {
    setInitialized(false);
    setLoading(true);
    setRepoInfo(repoInfo);
  };

  const onReleaseChange = (release: string) => {
    if (!repoInfo) return;

    HarvestRepoApi.getCommit(repoInfo.name, release, commitView).then(
      updateRelease
    );
  };

  const onCommitViewChange = (commitView: CommitView) => {
    setCommitView(commitView);
  };

  const onIncludeInterfacesChange = (includeInterfaces: boolean) => {
    if (!analyzedProj) return;

    const nodes = getClass2DiagramNodes(analyzedProj);
    const edges = getClass2DiagramEdges(analyzedProj);

    const { nodes: filteredNodes, edges: filteredEdges } = filterNodesAndEdges(
      nodes,
      edges,
      includeInterfaces,
      showEnums,
      showUnconnected
    );

    setShowInterfaces(includeInterfaces);
    setGraphNodes(filteredNodes);
    setGraphEdges(filteredEdges);
  };

  const onIncludeEnumsChange = (includeEnums: boolean) => {
    if (!analyzedProj) return;

    const nodes = getClass2DiagramNodes(analyzedProj);
    const edges = getClass2DiagramEdges(analyzedProj);

    const { nodes: filteredNodes, edges: filteredEdges } = filterNodesAndEdges(
      nodes,
      edges,
      showInterfaces,
      includeEnums,
      showUnconnected
    );

    setShowEnums(includeEnums);
    setGraphNodes(filteredNodes);
    setGraphEdges(filteredEdges);
  };

  const onFileHistorySelected = (fileName: string, version: number) => {
    if (!repoInfo) return;

    HarvestRepoApi.getCoverageMap(
      repoInfo.name,
      commitView,
      fileName,
      version
    ).then((coverageMap) => {
      setCoverageMap(coverageMap);
    });
  };

  const filterNodesAndEdges = (
    nodes: GraphVizNode<JavaRef | UmlFile>[],
    edges: GraphVizEdge<number>[],
    includeInterfaces: boolean,
    includeEnums: boolean,
    includeUnconnected: boolean
  ) => {
    const interfacesFiltered = includeInterfaces
      ? nodes
      : nodes.filter(
          (n) =>
            isUmlFile(n.data) ||
            (isJavaRef(n.data) && n.data.type !== "JavaInterfaceNode")
        );

    const enumsFiltered = includeEnums
      ? interfacesFiltered
      : interfacesFiltered.filter(
          (n) =>
            isUmlFile(n.data) ||
            (isJavaRef(n.data) && n.data.type !== "JavaEnumNode")
        );

    const filteredEdges = edges.filter(
      (e) =>
        enumsFiltered.some((n) => n.id === e.source) &&
        enumsFiltered.some((n) => n.id === e.target)
    );

    const filteredNodes = includeUnconnected
      ? enumsFiltered
      : enumsFiltered.filter((n) =>
          filteredEdges.some((e) => e.source === n.id || e.target === n.id)
        );

    return {
      nodes: filteredNodes,
      edges: filteredEdges,
    };
  };

  const onIncludeUnconnectedChange = (includeUnconnected: boolean) => {
    if (!analyzedProj) return;

    const nodes = getClass2DiagramNodes(analyzedProj);
    const edges = getClass2DiagramEdges(analyzedProj);

    const { nodes: filteredNodes, edges: filteredEdges } = filterNodesAndEdges(
      nodes,
      edges,
      showInterfaces,
      showEnums,
      includeUnconnected
    );

    setShowUnconnected(includeUnconnected);
    setGraphNodes(filteredNodes);
    setGraphEdges(filteredEdges);
  };

  const commitUrl = (commitView: CommitView, committish: string) => {
    return commitView === "releases"
      ? `http://github.com/${repoInfo?.name}/releases/${committish}`
      : `http://github.com/${repoInfo?.name}/commit/${committish}`;
  };

  return (
    <div className="App">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4}>
          <RepoSelectorCard
            repoInfo={repoInfo}
            onRepoChange={onRepoChange}
            commitView={commitView}
            onCommitViewChange={onCommitViewChange}
          />
        </Grid>
        <Grid item xs={7}>
          {initialized && repoInfo && coverageHistory && (
            <ReleaseMetricsCard
              releases={coverageHistory
                .map((c) => {
                  return {
                    created_date: c.created,
                    tag_name: c.committish,
                    html_url: commitUrl(commitView, c.committish),
                  };
                })
                .sort((a, b) => {
                  const dateA = new Date(a.created_date);
                  const dateB = new Date(b.created_date);
                  return dateA.valueOf() - dateB.valueOf();
                })}
              releaseInfo={releaseInfo}
              analyzedProj={analyzedProj}
              onReleaseChange={onReleaseChange}
            />
          )}
        </Grid>
        <Grid item xs={11}>
          {initialized && javaTree && edgeMap && (
            <CirclePackCard
              javaTree={javaTree}
              edgeMap={edgeMap}
              edgeMetric={edgeMetric}
              onMetricTypeChange={(metric) => setEdgeMetric(metric)}
              umlRefs={umlRefs}
            />
          )}
        </Grid>
        <Grid item xs={11}>
          {initialized && coverageHistory && javaProjHistory && (
            <HistoryVisualizerCard
              coverageHistory={coverageHistory}
              javaProjHistory={javaProjHistory}
              onSelectCommit={onReleaseChange}
            />
          )}
        </Grid>
        <Grid item xs={11}>
          {initialized && coverageHistory && javaProjHistory && (
            <GraphVisualizerCard
              nodes={graphNodes}
              edges={graphEdges}
              onChangeShowInterfaces={onIncludeInterfacesChange}
              showInterfaces={showInterfaces}
              onChangeShowUnconnected={onIncludeUnconnectedChange}
              showUnconnected={showUnconnected}
              onShowEnums={onIncludeEnumsChange}
              showEnums={showEnums}
            />
          )}
        </Grid>
        <Grid item xs={11}>
          <FileHistoryCard
            files={javaFiles}
            onFileSelected={onFileHistorySelected}
            coverageMap={coverageMap}
          />
        </Grid>
      </Grid>
      <LoadingModal isLoading={loading} />
    </div>
  );
}
