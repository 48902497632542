import React, { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { AnalyzedProj, ReleaseInfo } from "../api/harvest-repo-api-types";
import { MetricsViewer } from "./MetricsViewer";
import ReleaseSelector from "./ReleaseSelector";
import {
  MetricsItem,
  MetricsItemLink,
  MetricsList,
  MetricsTitle,
} from "./MetricsList";

const extractReleaseInfoMetrics = (releaseInfo: ReleaseInfo) => {
  return (
    <>
      <MetricsItemLink name="Link" url={releaseInfo.html_url} />
      <MetricsItem name="Tag" value={releaseInfo.tag_name} />
      <MetricsItem name="Creation Date" value={releaseInfo.created_date} />
    </>
  );
};

interface Props {
  releases: ReleaseInfo[];
  releaseInfo?: ReleaseInfo;
  analyzedProj?: AnalyzedProj;
  onReleaseChange: (release: string) => void;
}
export const ReleaseMetricsCard = ({
  releases,
  releaseInfo,
  analyzedProj,
  onReleaseChange,
}: Props) => {
  const [currentReleaseIndex, setCurrentReleaseIndex] = useState(
    releases.length - 1
  );

  const _onReleaseChange = (release: string) => {
    const index = releases.findIndex((r) => r.tag_name === release);
    setCurrentReleaseIndex(index);
    onReleaseChange(release);
  };

  const releaseMetrics = releaseInfo ? (
    extractReleaseInfoMetrics(releaseInfo)
  ) : (
    <></>
  );

  return (
    <Paper elevation={4} sx={{ height: "100%" }}>
      <Box p={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Release Metrics</Typography>
          </Grid>
          <Grid item xs={8}>
            {analyzedProj && <MetricsViewer analyzedProj={analyzedProj} />}
          </Grid>
          <Grid item xs={4}>
            {releaseInfo && (
              <MetricsList>
                <MetricsTitle title="Release Metrics" />
                {releaseMetrics}
              </MetricsList>
            )}

            <ReleaseSelector
              releases={releases}
              currentReleaseIndex={currentReleaseIndex}
              onReleaseChange={_onReleaseChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
