import React from "react";
import { JavaNode } from "../utils/circle-pack-data-converter";
import {
  RefEdge,
  EdgeMetric,
  edgeMetrics,
} from "../api/harvest-repo-api-types";
import {
  Aggregation,
  calculateAggregatedMetric,
  getLeafRefs,
} from "../utils/metrics-calculator";
import { Grid } from "@mui/material";
import {
  MetricsItem,
  MetricsList,
  MetricsTitle,
  MetricsItemDropdownList,
  stringToDropdownItem,
} from "./MetricsList";

interface Props {
  node: JavaNode;
  aggregationType: Aggregation;
  metricsType: EdgeMetric;
  edgeMap: Map<number, RefEdge[]>;
  umlRefs: string[];
}
export const JavaCirclePackToolTip = ({
  node,
  aggregationType,
  metricsType,
  edgeMap,
  umlRefs,
}: Props) => {
  if (node.data === "root") return <></>;

  const javaNode = node.data;
  const metrics = edgeMetrics.map((m) => {
    const v = calculateAggregatedMetric(javaNode, m, aggregationType, edgeMap);
    return <MetricsItem name={m} value={v.toFixed(2)} />;
  });

  const name =
    javaNode.type === "JavaPackNode"
      ? javaNode.data.fully_qualified_name
      : javaNode.data.name;

  const leafRefs = getLeafRefs(javaNode);
  const refNames = leafRefs.map((r) => r.data.name).sort();
  const coveredRefNames = leafRefs
    .filter((r) => edgeMap.get(r.id) !== undefined)
    .map((r) => r.data.name)
    .sort();

  const coveredRefSet = new Set(coveredRefNames);
  const umlRefsNotCovered = umlRefs.filter((ref) => !coveredRefSet.has(ref));

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <MetricsList>
          <MetricsTitle title="Node Data" />
          <MetricsItem name="Type" value={javaNode.type} />
          <MetricsItem name="Name" value={name} />
          <MetricsItemDropdownList
            title="Java References"
            items={refNames.map(stringToDropdownItem)}
          />
          <MetricsItemDropdownList
            title="Covered Java References"
            items={coveredRefNames.map(stringToDropdownItem)}
          />
          <MetricsItemDropdownList
            title="UML References"
            items={umlRefs.sort().map(stringToDropdownItem)}
          />
          <MetricsItemDropdownList
            title="UML References - Covered Java References"
            items={umlRefsNotCovered.sort().map(stringToDropdownItem)}
          />
        </MetricsList>
      </Grid>
      <Grid item>
        <MetricsList>
          <MetricsTitle title="Metrics" />
          {metrics}
        </MetricsList>
      </Grid>
    </Grid>
  );
};
