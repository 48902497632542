import * as d3 from "d3";
import { useLayoutEffect, useRef } from "react";
import { ZoomView } from "d3";

import "./CirclePack.css";
import React from "react";

// worked from https://observablehq.com/@d3/zoomable-circle-packing and here http://using-d3js.com/06_05_packs.html

export interface Node {
  name: string;
  value?: number;
  children?: Node[];
}

const pack = <T extends Node>(data: T, width: number, height: number) => {
  return d3.pack<T>().size([width, height]).padding(3)(
    d3
      .hierarchy(data)
      .sum((d) => d.value || 0)
      .sort((a, b) => (b.value && a.value ? b.value - a.value : 0))
  );
};

const color = d3
  .scaleLinear<string>()
  .domain([0, 5])
  .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
  .interpolate(d3.interpolateHcl);

const leafColor = d3
  .scaleLinear<string>()
  .domain([0.0, 1.0])
  .range(["hsl(1,50%,50%)", "hsl(125,50%,50%)"])
  .interpolate(d3.interpolateHcl);

// move center of node to desired position
const translateNode =
  (diameter: number) =>
  ([x, y, r]: ZoomView) =>
  (d: d3.HierarchyCircularNode<any>) => {
    const k = diameter / r;
    return `translate(${(d.x - x) * k},${(d.y - y) * k})`;
  };

//
const sizeNode =
  (diameter: number) =>
  ([x, y, r]: ZoomView) =>
  <T extends Node>(d: d3.HierarchyCircularNode<T>) => {
    const k = diameter / r;
    return d.r * k;
  };

type Props<T extends Node> = {
  data: T;
  width: number;
  height: number;
  margin?: number;
  onMouseOver: (node: T) => void;
  leafIntensity: (node: T) => number | "None"; // number between 0-100
};
const _CirclePack = <T extends Node>({
  data,
  width,
  height,
  margin = 0,
  onMouseOver,
  leafIntensity,
}: Props<T>) => {
  const diameter = width - margin / 2;

  const view = useRef<ZoomView>([0, 0, 0]);
  const containerRef = useRef<SVGSVGElement>(null);

  useLayoutEffect(() => {
    const svg = d3.select<SVGSVGElement | null, T>(containerRef.current);
    const root = pack(data, width - margin, height - margin);
    let focus = root;

    // handle zoom events
    const zoom = (event: PointerEvent, d: d3.HierarchyCircularNode<T>) => {
      focus = d;

      d3.transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", (d) => {
          const newView: ZoomView = [focus.x, focus.y, focus.r * 2];
          const interpolate = d3.interpolateZoom(view.current, newView);
          return (t) => zoomTo(interpolate(t));
        });
    };
    const zoomTo = (zoomView: ZoomView) => {
      view.current = zoomView;

      node.attr("transform", translateNode(diameter)(zoomView));
      node.attr("r", sizeNode(diameter)(zoomView));
    };

    // zoom in on selected nodes if they aren't already in focus
    const clickNode = (e: PointerEvent, d: d3.HierarchyCircularNode<T>) => {
      // zoom only if the node is not already in focus
      if (focus !== d) {
        zoom(e, d);
      }
      e.stopPropagation();
    };

    const colorNode = (d: d3.HierarchyCircularNode<T>) => {
      if (d.children) {
        return color(d.depth);
      }
      const intensity = leafIntensity(d.data);
      if (intensity === "None") {
        return "white";
      }
      return leafColor(intensity);
    };

    // create nodes (circles)
    const node = svg
      .select("g")
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      .attr("fill", colorNode)
      .on(
        "mouseover",
        function (e: PointerEvent, d: d3.HierarchyCircularNode<T>) {
          d3.select(this).attr("stroke", "#000");
          onMouseOver(d.data);
        }
      )
      .on("mouseout", function () {
        d3.select(this).attr("stroke", null);
      })
      .on("click", clickNode);

    // zoom to root node by default, and zoom to root when selecting the SVG background
    zoomTo([root.x, root.y, root.r * 2]);
    svg.on("click", (event: PointerEvent) => zoom(event, root));
  });

  return (
    <svg width={`${width}px`} height={`${height}px`} ref={containerRef}>
      <g transform={`translate(${diameter / 2}, ${diameter / 2})`} />
    </svg>
  );
};

export const CirclePack = React.memo(_CirclePack) as typeof _CirclePack;
