import {
  JavaPack,
  JavaClass,
  isJavaRef,
  JavaRef,
} from "../api/harvest-repo-api-types";

export const getRefs = (jNode: JavaPack | JavaRef): JavaRef[] => {
  if (jNode.type === "JavaPackNode") {
    const refs = jNode.children.filter(isJavaRef).map((c) => c as JavaClass);
    const packages = jNode.children
      .filter((c) => c.type === "JavaPackNode")
      .map((c) => c as JavaPack);
    return [...refs, ...packages.flatMap(getRefs)];
  } else {
    return [jNode];
  }
};
