import { ListItem, ListItemText } from "@mui/material";
import React from "react";

interface Props {
  name: string;
  value: string | number;
}
export const MetricsItem = ({ name, value }: Props) => {
  return (
    <ListItem key={name}>
      <ListItemText primary={name} secondary={value} />
    </ListItem>
  );
};
