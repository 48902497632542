import React from "react";
import "./LoadingModal.css";
import { FaSpinner } from "react-icons/fa"; // Spinner icon

type Props = {
  isLoading: boolean;
};
const LoadingModal = ({ isLoading }: Props) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <FaSpinner className="loading-icon" />
    </div>
  );
};

export default LoadingModal;
